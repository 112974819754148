@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,600');
@import url('https://fonts.googleapis.com/css?family=Yesteryear&text=hi!');

body {
  background-color: #262626;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Mono, -apple-system, BlinkMacSystemFont, sans-serif;
}
::selection {
  background-color: #384dd8;
  color: #8a94d3;
}
