.project {
  animation-fill-mode: none !important;
  padding: 3vw 9vw;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  .title {
    font-size: 27px;
    color: white;
    margin: 6px;
    text-transform: uppercase;
  }
  .logo {
    border-radius: 24px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
    width: 96px;
    margin: 6px;
    &.lelivre,
    &.stockr {
      border-radius: 0px;
      filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
      width: 150px;
    }
  }
  .description {
    line-height: 1.6em;
    margin: 24px 6px;
    text-align: justify;
    text-align-last: center;
  }
  .source {
    margin: 12px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  }
  .tags {
    padding: 6px;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    .tag {
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 3px 9px;
      margin: 2px;
      cursor: default;
      user-select: none;
      &.time,
      &.team-size {
        color: rgba(255, 255, 255, 0.7);
        background-color: rgba(255, 255, 255, 0.1);
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        text-transform: uppercase;
      }
      &.tech {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .gallery {
    margin: 12px 0;
    .pswp-thumbnail {
      display: inline-block;
      margin: 3px;
      img {
        height: 12vmin;
        width: auto;
        border-radius: 9px;
        cursor: zoom-in;
        transition: all 0.2s ease-out;
        border: 3px solid transparent;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
        &:hover {
          transform: scale(1.3) translateY(-6px);
          box-shadow: 0px 0px 36px 21px rgba(0, 0, 0, 0.2);
          border: 3px solid #0b65ff;
        }
        &:active {
          filter: brightness(0.7);
        }
      }
    }
    .pswp__caption,
    .pswp__counter {
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }
    .pswp__caption__center {
      text-align: center;
      font-size: 15px;
    }
    .pswp__button--share {
      display: none;
    }
    .pswp__bg {
      opacity: 0.9 !important;
    }
    .pswp__img {
      border-radius: 12px;
      box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 0.6);
    }
  }
}
