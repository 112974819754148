.button {
  font-size: 18px;
  background: none;
  border-radius: 25px;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 6px 9px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  user-select: none;
  transition: 0.3s all ease;
  &:hover {
    border-color: rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.9);
  }
  &:active {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.7);
    color: rgba(0, 0, 0, 0.7);
  }
}
