.about {
  .about-header {
    font-size: 6vmax;
    letter-spacing: 15px;
    position: absolute;
    text-align: center;
    top: 15vmin;
    left: 0;
    right: 0;
    margin: auto;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 0px 48px rgba(0, 0, 0, 0.9);
    text-transform: uppercase;
  }
  .about-details {
    padding: 24px;
    background-color: #323d52;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    .separator {
      margin: 24px 0;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.3);
    }
    .about-picture {
      margin: 15px auto;
      background-image: url(../../assets/images/mk.jpg);
      background-size: cover;
      width: 72px;
      height: 72px;
      box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
    .about-title {
      text-transform: uppercase;
      color: rgb(134, 230, 207);
      font-size: 21px;
      margin: 9px 0;
    }
    .about-interests {
      .about-interests-list {
        line-height: 1.8em;
      }
    }
    .about-education,
    .about-experience {
      .about-education-title,
      .about-experience-title {
        color: rgb(80, 192, 166);
        font-size: 18px;
      }
      .about-education-info,
      .about-experience-info {
        margin: 9px 0;
        color: rgba(255, 255, 255, 0.6);
        line-height: 1.8em;
        font-size: 15px;
        .about-experience-desc {
          color: rgba(255, 255, 255, 0.7);
          font-style: oblique;
          margin-bottom: 9px;
        }
        .tag {
          display: inline-flex;
          font-size: 12px;
          border-radius: 6px;
          line-height: 1em;
          border: 1px solid rgba(255, 255, 255, 0.3);
          padding: 3px 6px;
          cursor: default;
          user-select: none;
          &.time {
            color: rgba(255, 255, 255, 0.7);
            background-color: rgba(255, 255, 255, 0.1);
            text-transform: uppercase;
          }
        }
      }
    }
    .about-links {
      margin: 12px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .link-btn {
        margin: 3px;
        padding: 3px 6px;
        width: 42px;
        color: white;
        background: none;
        font-size: 36px;
        border-radius: 6px;
        text-decoration: none;
        transition: 0.6s all ease-out;
        :active {
          transform: scale(0.95);
          transition: 0.3s all ease-in-out;
          color: #c6c6c6;
        }
        &.linkedin:hover {
          background-color: #0077b5;
        }
        &.github:hover {
          background-color: #6e5494;
        }
        &.twitter:hover {
          background-color: #1da1f2;
        }
        &.instagram:hover {
          background-color: #c13584;
        }
        &.resume:hover {
          background-color: #00a98f;
          cursor: alias;
        }
        &.email:hover {
          background-color: #0f93ff;
          cursor: ne-resize;
        }
      }
    }
  }
}
