.footer {
  text-align: center;
  padding: 9px;
  color: rgb(122, 122, 122);
  font-size: 12px;
  .footer-react {
    a {
      color: rgb(87, 87, 87);
    }
  }
}
