.header {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 48px rgba(0, 0, 0, 0.6);
  user-select: none;
  cursor: default;
  position: relative;
  .message {
    font-family: Yesteryear;
    font-size: 21vh;
    margin: 36vh auto;
    animation-fill-mode: both;
  }
  .down-arrow {
    font-size: 12vh;
    position: absolute;
    bottom: 1vh;
    left: 0;
    right: 0;
    margin: auto;
    text-shadow: 0px 0px 48px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    cursor: default;
    &:hover {
      text-shadow: 0px 0px 48px rgba(255, 255, 255, 0.6);
      color: rgba(240, 240, 240, 1);
    }
    &:active {
      text-shadow: 0px 0px 48px rgba(0, 0, 0, 0.9);
      color: rgba(240, 240, 240, 0.3);
    }
  }
}
